<template>
  <ul>
    <template v-for="(item, key) in modifiedList" :key="key">
      <hr v-if="item === 'break'"  />
      <li v-else-if="links[key]">
        <nuxt-link :to="links[key]" v-html="item"></nuxt-link>
      </li>
      <li v-else v-html="item"></li>
    </template>
    <template v-if="list.length > truncateLength">
      <t-button-inline v-if="expanded" @click.stop="expanded = false">Show Less</t-button-inline>
      <t-button-inline v-else @click.stop="expanded = true">Show {{remainder}} More</t-button-inline>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'expandableList',
  props: {
    list: {
      type: [Array, Object],
      default: () => []
    },
    truncateLength: {
      type: Number,
      default: 10
    },
    highlight: {
      type: [Object, Array, Boolean]
    },
    replaceEmptyStrings: {
      type: Boolean,
      default: false
    },
    links: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    modifiedList () {
      if (this.expanded) {
        return this.injectHtml(this.list)
      }
      return this.injectHtml(this.list).slice(0, this.truncateLength)
    },
    remainder () {
      return this.injectHtml(this.list).length - this.truncateLength
    }
  },
  methods: {
    injectHtml (obj) {
      const highlighted = []
      const arr = []
      if (Array.isArray(obj)) {
        for (const item of obj) {
          if (this.highlight && !this.highlight.includes(item)) {
            arr.push(`<span class="text-muted">${item}</span>`)
          } else if (item === '' && this.replaceEmptyStrings) {
            arr.push('break')
          } else {
            highlighted.push(`<span>${item}</span>`)
          }
        }
      } else {
        const highlight = this.highlight && Object.keys(this.highlight)
        for (const [key, value] of Object.entries(obj)) {
          if (typeof value === 'boolean') {
            if (this.highlight && !highlight.includes(key)) {
              arr.push(`<span class="text-muted">${key}: ${value}</span>`)
            } else {
              const classes = value ? 'text-success' : 'text-danger'
              highlighted.push(`${key}: <span class="${classes}">${value}</span>`)
            }
          } else {
            highlighted.push(`${key}: <b>${value}</b>`)
          }
        }
      }
      return highlighted.concat(arr)
    }
  }
}
</script>
